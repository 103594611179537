.regis {
    width: 100%;
    float: left;
    margin: 50px 0 100px;
    min-height: 180px;
}
.regis form {
    width: 30%;
    margin: 0 auto;
    font-family: 'Open Sans Condensed', sans-serif;
}
.regis form input {
    width: 100%;
    float: left;
    margin-top: 10px;
    font-size: 13px;
    padding-left: 10px;
    height: 30px;
    font-weight: 600;
    border: 2px solid #3f2803;
    letter-spacing: 1.5;
}
.regis form input:nth-child(2) {
    margin-top: 40px;
}

.regis form input:focus {
    border-color: #a0d445;
    outline: none;
}

::-webkit-input-placeholder {
    color: #dad6d6;
    text-transform: uppercase;
    font-size: 13px;
}
.regis form input[type="submit"] {
    width: 150px;
    background: #a0d445;
    color: #fff;
    line-height: 1.5;
    letter-spacing: 1.5;
    cursor: pointer;
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
    border: none;

}
.regis form input[type="submit"]:hover {
    background: #3f2803;
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
}
.regis form .massage {
    height: 15px;
    float: left;
    width: 100%;
}
.regis form p {
    color: #ff0000;
    float: left;
    font-size: 14px;
    line-height: 1.7;
}
.regis form p:first-of-type {
    color: #86da27;

}
.regis form p:first-of-type span {
    padding-right: 10px;
}
.successful {
    float: left;
    width: 100%;
}
.successful .box {
    margin: 80px auto;
    width: 645px;
    text-align: center;
}
.regis .successful .box {
    margin: 0 auto;
    height: 66px;
}
.successful .box h2 {
    font-weight: 600;
}
.successful .box h3 {
    line-height: 1.3;
}
.forgot_pass {
    text-transform: uppercase;
    padding: 18px 0px 18px 18px;
    color: #adadad;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.287em;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
    cursor: pointer;
    text-decoration: none;
    font-family: "Open Sans";
    float: right;
}
.forgot_pass:hover {
    color: #86da27;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
}
@media(max-width:  1000px) { 
    .regis form {
        width: 40%;
    }
}
@media(max-width:  700px) { 
    .regis form {
        width: 50%;
    }
    .successful .box {
        width: 90%;
    }
    .successful .box h2 {
        line-height: 1.3;
        font-size: 25px;
    }
    .successful .box h3 {
        font-size: 18px;
    }
}
@media (max-width: 500px) {
    .regis form {
        width: 80%;
    }
    .regis form input {
        margin-top: 5px;
        padding-left: 2px;
        letter-spacing: 0.5px;
    }
    .regis form input:nth-child(2) {
        margin-top: 10px;
    }
    footer .text h5 {
        letter-spacing: 0.5px;
    }
}